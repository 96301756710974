/**
 * Convert strings to monthDay object
 * @param birthday - MM/DD/YYYY, YYYY/MM/DD,  MM/DD, or Fri Dec 28 00:00:00 GMT 1990
 * @returns { dobMonth: number, dobDay: number }
 */
export function toMonthDay(birthday) {
  const date = new Date(birthday);

  if (date instanceof Date && !isNaN(date.getTime()) && date) {
    const dobMonth = date.getMonth() + 1;
    const dobDay = date.getDate();
    return { dobMonth, dobDay };
  }
  return null;
}
