import React, { useState, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import isMobilePhone from "validator/lib/isMobilePhone";
import { isValidEmail } from "util/helpers";

export default function ColumnItem(props) {
  let { columnSamples, csvColumnHeader, properties } = props;
  let [mappedColumn, setMappedColumn] = useState("N/A");

  useEffect(() => {
    //if one of our csv headers equals the id of a property, set its value to mapped
    //our standard property ids = their column headers
    let tempProperties = { ...props.properties };
    for (const [key, value] of Object.entries(props.properties)) {
      if (
        value.columnHeader.toLowerCase() ===
        props.csvColumnHeader.toLowerCase().replace(/ /g, "")
      ) {
        tempProperties[key].mapped = true;
        tempProperties[key].columnHeader = props.csvColumnHeader;
        setMappedColumn(props.properties[key].id || key);
        props.setProperties(tempProperties);
        break;
      }
    }
  }, []);

  //build the left side of the column item
  const columnValues = Object.values(props.columnSamples).map((columnSample, index) => {
    const value = columnSample[csvColumnHeader];
    return (
      <span key={index}>
        {!!value && typeof value === "object"
          ? "Error: make sure your csv file contains column headers( ie - email, firstName, etc.)"
          : value || ""}
        <br />
      </span>
    );
  });

  //build the right side of the column item
  const menuItems = Object.entries(properties).map(
    ([key, { mapped, label, id }], index) => (
      <option key={index} disabled={mapped} value={id || key}>
        {label}
      </option>
    )
  );

  const changeColumn = (e) => {
    //set new column mapped to true, and set old value to false
    let existingMappedColumn = mappedColumn;
    let newMappedColumn = e.target.value;
    var tempProperties = { ...props.properties };
    if (existingMappedColumn !== "N/A")
      tempProperties[existingMappedColumn].mapped = false;
    if (newMappedColumn !== "N/A") tempProperties[newMappedColumn].mapped = true;

    //change property column header
    if (existingMappedColumn !== "N/A")
      delete tempProperties[existingMappedColumn].columnHeader;
    if (newMappedColumn !== "N/A")
      tempProperties[newMappedColumn].columnHeader = csvColumnHeader;

    props.setProperties(tempProperties);
    setMappedColumn(e.target.value);
  };

  //prepare values to check for incorrect formats
  let emailColumnHeader,
    firstEmailRowValue,
    cellNumberColumnHeader,
    firstCellNumberRowValue,
    textOptInColumnHeader,
    firstTextOptInRowValue,
    dobDayColumnHeader,
    firstDobDayRowValue,
    dobMonthColumnHeader,
    firstDobMonthRowValue;
  emailColumnHeader = (properties["email"] || properties["requestEmail"]).columnHeader;
  firstEmailRowValue = columnSamples[0][emailColumnHeader];

  //if normal contact upload then there are other properties we need to check for
  //this will throw an error under task
  if (props.calledFromContacts) {
    cellNumberColumnHeader = properties["cellNumber"].columnHeader;
    firstCellNumberRowValue = columnSamples[0][cellNumberColumnHeader];
    textOptInColumnHeader = properties["textOptIn"].columnHeader;
    firstTextOptInRowValue = columnSamples[0][textOptInColumnHeader];
    dobDayColumnHeader = properties["dobDay"].columnHeader;
    firstDobDayRowValue = parseInt(columnSamples[0][dobDayColumnHeader], 10);
    dobMonthColumnHeader = properties["dobMonth"].columnHeader;
    firstDobMonthRowValue = parseInt(columnSamples[0][dobMonthColumnHeader], 10);
  }

  let tooltipTitle;
  let icon;
  let iconColor;
  if (mappedColumn === "N/A") {
    tooltipTitle =
      "This data isn't mapped. If you choose not to map a data type it won't be uploaded and used.";
    icon = "warning";
    iconColor = "orange";
  } else if (
    firstEmailRowValue &&
    mappedColumn === "email" &&
    !isValidEmail(firstEmailRowValue)
  ) {
    tooltipTitle =
      "Your emails are not formatted correctly and the data won't be uploaded.";
    icon = "error";
    iconColor = "red";
  } else if (
    firstCellNumberRowValue &&
    mappedColumn === "cellNumber" &&
    !isMobilePhone(firstCellNumberRowValue)
  ) {
    tooltipTitle =
      "Your cell numbers are not formatted correctly and the data won't be uploaded. Make sure to include a mobile cell number.";
    icon = "error";
    iconColor = "red";
  } else if (
    firstTextOptInRowValue &&
    mappedColumn === "textOptIn" &&
    firstTextOptInRowValue !== ""
  ) {
    //isBoolean doesn't count "TRUE" of "True"
    if (
      firstTextOptInRowValue === "true" ||
      firstTextOptInRowValue === "TRUE" ||
      firstTextOptInRowValue === "True" ||
      firstTextOptInRowValue === true ||
      firstTextOptInRowValue === "yes" ||
      firstTextOptInRowValue === "Yes" ||
      firstTextOptInRowValue === "YES" ||
      firstTextOptInRowValue === "Y" ||
      firstTextOptInRowValue === "y"
    ) {
      tooltipTitle = "This data has been mapped. Mapped data will be uploaded.";
      icon = "check_circle";
      iconColor = "green";
    } else {
      tooltipTitle =
        'Text Opt In is incorrectly formatted. Must be "true" or "false" or blank.';
      icon = "error";
      iconColor = "red";
    }
  } else if (
    firstDobDayRowValue &&
    mappedColumn === "dobDay" &&
    !(firstDobDayRowValue >= 1 && firstDobDayRowValue <= 31)
  ) {
    tooltipTitle =
      "Your day is not formatted correctly and the data won't be uploaded. Must be a number between 1 and 31.";
    icon = "error";
    iconColor = "red";
  } else if (
    firstDobMonthRowValue &&
    mappedColumn === "dobMonth" &&
    !(firstDobMonthRowValue >= 1 && firstDobMonthRowValue <= 12)
  ) {
    tooltipTitle =
      "Your month is not formatted correctly and the data won't be uploaded. Must be a number between 1 and 12.";
    icon = "error";
    iconColor = "red";
  } else {
    tooltipTitle = "This data has been mapped. Mapped data will be uploaded.";
    icon = "check_circle";
    iconColor = "green";
  }

  let selectLabel = props.calledFromBulkRequest
    ? "Request Property"
    : "Gratavid Contact Property";

  return (
    <div
      className="col-12 row"
      style={{
        padding: "10px",
        border: "1px #ddd solid",
        marginTop: "4px",
        borderLeft: "5px " + iconColor + " solid",
        borderRadius: "2px",
      }}>
      <div className="col-6" style={{ overflow: "hidden" }}>
        <b>{csvColumnHeader}</b>
        <br />
        {columnValues}
      </div>
      <div className="col-6 align-items-start" style={{ display: "flex" }}>
        <div style={{ width: "35px", marginTop: "15px" }}>
          <Tooltip title={<span style={{ fontSize: "15px" }}>{tooltipTitle}</span>}>
            <IconButton aria-label="emailIcon" size="small">
              <i className="material-icons" style={{ color: iconColor }}>
                {icon}
              </i>
            </IconButton>
          </Tooltip>
        </div>
        <div style={{ flex: 1, marginTop: "10px" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor={"select-colum-for-" + csvColumnHeader}>
              {selectLabel}
            </InputLabel>
            <Select
              value={mappedColumn}
              native
              onChange={changeColumn}
              placeholder=""
              inputProps={{
                name: "select-colum-for-" + csvColumnHeader,
                id: "select-colum-for-" + csvColumnHeader,
              }}>
              <option value="N/A">Do Not Upload</option>
              {menuItems}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
