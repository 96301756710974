import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import UploadFile from "./uploadFile";
import ShowProperties from "../../../../util/contacts/showProperties";
import MapColumns from "./map/mapColumns";
import { Stepper, LinearProgress, Step, StepLabel, Button } from "@material-ui/core";
import FoldersAndConfirm from "./foldersAndConfirm/foldersAndConfirm";
import LoadingButtonCheckProductLimits from "../../../../util/buttons/productLimits/loadingbutton";
import { getContactCustomProps } from "services/api.service";
import { isValidEmail } from "util/helpers";
import defaultContactProperties from "components/util/data/defaultContactProperties.json";
import { mapRowToProperties } from "util/csv";
export default function AddContactsBulk(props) {
  let [csvData, setCsvData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  let [activeStep, setActiveStep] = useState(0);
  let [isLoading, setLoading] = useState(false);
  let [properties, setProperties] = useState({});

  useEffect(() => {
    setLoading(true);
    getContactCustomProps()
      .then((contactProperties) => {
        // JSON.parse(JSON.stringify ensures we do a deep copy of object. Spread operator is shallow copy.
        // Problem before json was, someone would change un-map property which deleted column header
        // then they would open it again and property would look mapped but it would miss column header so wouldn't map
        const defaultProperties = JSON.parse(JSON.stringify(defaultContactProperties));

        if (contactProperties) {
          for (const key in contactProperties) {
            contactProperties[key].mapped = false;
            contactProperties[key].columnHeader = contactProperties[key].label;
          }
        }

        const allProperties = { ...defaultProperties, ...contactProperties };
        setProperties(allProperties);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleCsvChange = (data) => {
    setErrorMessage("");
    setCsvData(data);
  };

  let goToConfirmStep = () => {
    //verify email is correct
    const emailSet = new Set();
    let rowNum = 2; // header is row 1, data starts on row 2

    for (const row of csvData.rows) {
      const { email, firstName, lastName } = mapRowToProperties(row, properties);
      if (emailSet.has(email.toLowerCase())) {
        setErrorMessage(
          `Duplicate email: ${email}. Please make sure all emails are unique.`
        );
        return;
      }

      if (properties.email.mapped !== true) {
        setErrorMessage(
          'Email not mapped. Make sure to select "Email" under at least one "Customer Property".'
        );
        return;
      }

      if (!isValidEmail(email)) {
        setErrorMessage(
          `Row ${rowNum}: ${email} is an invalid email. Make sure you you marked the correct email column under "Customer Property".`
        );
        return;
      }

      if (!firstName || !lastName) {
        setErrorMessage(
          `Row ${rowNum} is missing first name or last name. Please make sure all rows have first name and last name.`
        );
        return;
      }

      emailSet.add(email.toLowerCase());
      rowNum++;
    }

    setErrorMessage("");
    setActiveStep(2);
  };

  if (isLoading)
    return (
      <div style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );

  let steps = ["Prepare File", "Map Columns", "Confirm Upload"];
  let { totalRowCount } = csvData;

  return (
    <div style={{ position: "relative" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className="row col-12 pagePadding">
        {activeStep === 0 && (
          <>
            <ShowProperties properties={properties} id="bulkImportContacts" />
            <UploadFile
              properties={properties}
              setProperties={setProperties}
              {...props}
              setCsvData={handleCsvChange}
              csvData={csvData}
              setActiveStep={setActiveStep}
            />
            <div className="bulkUploadActionBar" style={{ justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!csvData.rows}
                onClick={() => setActiveStep(1)}>
                Next
                <i className="material-icons">arrow_forward</i>
              </Button>
            </div>
          </>
        )}

        {activeStep === 1 && (
          <>
            <MapColumns
              csvData={csvData}
              {...props}
              properties={properties}
              setProperties={setProperties}
              calledFromContacts={true}
            />
            <div className="bulkUploadActionBar">
              <div style={{ flex: 1 }}>
                {errorMessage && (
                  <div className="errorMessageBox" style={{ margin: "0px" }}>
                    <b>{errorMessage}</b>
                  </div>
                )}
              </div>
              <div className="justify-content-end" style={{ width: "220px" }}>
                <Button
                  variant="outlined"
                  onClick={() => setActiveStep(0)}
                  style={{ marginRight: "20px" }}>
                  Back
                </Button>

                <LoadingButtonCheckProductLimits
                  countToAdd={totalRowCount}
                  productKey="contacts"
                  productLabel="contact"
                  userIsAdmin={props.userIsAdmin}
                  accountInfo={props.accountInfo}
                  variant="contained"
                  color="primary"
                  savedLabel="Success"
                  label="Next step"
                  clickedRegularButton={goToConfirmStep}
                  clickedSavedButton={goToConfirmStep}
                  disabled={!csvData.rows}
                  showErrorAsTooltip={true}
                />
              </div>
            </div>
          </>
        )}

        {activeStep === 2 && (
          <FoldersAndConfirm
            accountInfo={props.accountInfo}
            bulkContactsAdded={props.bulkContactsAdded}
            csvData={csvData}
            properties={properties}
            setActiveStep={setActiveStep}
            setProperties={setProperties}
          />
        )}
      </div>
    </div>
  );
}

AddContactsBulk.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  userIsAdmin: PropTypes.bool,
  bulkContactsAdded: PropTypes.func,
};
