import React, { useState, useEffect } from "react";
import ShowProperties from "../../../../util/contacts/showProperties";
import MapColumns from "../../../contacts/addContact/bulk/map/mapColumns";
import UploadFile from "../../../contacts/addContact/bulk/uploadFile";
import { Stepper, LinearProgress, Step, StepLabel, Button } from "@material-ui/core";
import ConfirmAndAdd from "./confirmAndAdd";
import { Link } from "react-router-dom";
import { getContactCustomProps } from "services/api.service";
import defaultTaskProperties from "components/util/data/defaultTaskProperties.json";

export default function AddBulkStepper(props) {
  let [csvData, setCsvData] = useState({});
  let [activeStep, setActiveStep] = useState(0);
  let { bulkTasksDisabled } = props;
  const [isLoading, setLoading] = useState(false);
  let [properties, setProperties] = useState({});

  let steps = ["Prepare File", "Map and Upload"];

  useEffect(() => {
    setLoading(true);
    getContactCustomProps()
      .then((contactProperties) => {
        if (contactProperties) {
          for (const key in contactProperties) {
            contactProperties[key].mapped = false;
            contactProperties[key].columnHeader = contactProperties[key].label;
          }
        }

        setProperties({ ...defaultTaskProperties, ...contactProperties });
      })
      .finally(() => setLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    );
  }

  if (bulkTasksDisabled)
    return (
      <div className="col-12 row">
        <p style={{ fontSize: "15px" }}>
          Your current Gratavid plan does not include uploading tasks in bulk. You must
          add task one at a time or upgrade your plan.
        </p>

        <div className="col-12" style={{ margin: "15px 0px 30px 0px" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/a/${props.accountInfo.id}/subscription`}>
            Upgrade Plan
          </Button>
        </div>
      </div>
    );

  return (
    <div style={{ position: "relative" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className="row col-12 pagePadding">
        {activeStep === 0 && (
          <>
            <ShowProperties properties={properties} id="bulkImportTasks" />

            <UploadFile
              properties={properties}
              setProperties={setProperties}
              {...props}
              setCsvData={setCsvData}
              csvData={csvData}
              setActiveStep={setActiveStep}
              hideUploadWarning={true}
            />
            <div className="bulkUploadActionBar" style={{ justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!csvData.contacts}
                onClick={() => setActiveStep(1)}>
                Next
                <i className="material-icons">arrow_forward</i>
              </Button>
            </div>
          </>
        )}

        {activeStep === 1 && (
          <>
            <MapColumns
              csvData={csvData}
              {...props}
              properties={properties}
              setProperties={setProperties}
              calledFromTask={true}
            />
            <ConfirmAndAdd
              {...props}
              properties={properties}
              csvData={csvData}
              setActiveStep={setActiveStep}
              setProperties={setProperties}
            />
          </>
        )}
      </div>
    </div>
  );
}
