import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./videoRecorder.module.css";
import {
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Box,
  IconButton,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  createVideoSignedUrl,
  editVideo,
  getVideo,
  createVideos,
} from "services/api.service";
import axios from "axios";
import {
  segmentBackground,
  applyBlur,
  applyImageBackground,
  startSegmenting,
  stopSegmenting,
} from "services/virtualBg";

const STATES = {
  error: "error",
  init: "init",
  recordingCountdown: "recordingCountdown",
  recording: "recording",
  recorded: "recorded",
  uploading: "uploading",
  processing: "processing",
  completed: "completed",
};

const MAX_FILE_SIZE = 734003200; // 700MB
const ERROR_RECORDING = "Error recording. Please upload an existing file.";

export default function VideoRecorder(props) {
  const [status, setStatus] = useState(STATES.init);
  const [countdownSecond, setCountdownSecond] = useState(null);
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState("");
  const [videoDevices, setVideoDevices] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [blob, setBlob] = useState(null);
  const [background, setBackground] = useState("None");
  const [isLoading, setIsLoading] = useState(true);
  const [isMirrored, setIsMirrored] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const videoRef = useRef();
  const canvasRef = useRef();
  const previewRef = useRef();
  const fileInput = useRef();
  const fileUpload = useRef();

  const poll = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const videoStream = useRef(null);
  const canvasStream = useRef(null);
  const isSafari = useRef(
    navigator.userAgent.includes("Safari") &&
      !navigator.userAgent.includes("Chrome") & !navigator.userAgent.includes("Chromium")
  );

  useEffect(() => {
    if (typeof MediaRecorder === "undefined") {
      setStatus(STATES.error);
      setErrorMessage("MediaRecorder is not supported in this browser.");
      setIsLoading(false);
      return;
    }

    const videoSetup = async () => {
      let deviceId = null;
      const vd = (await window.navigator.mediaDevices.enumerateDevices()).filter(
        (device) => device.kind === "videoinput"
      );
      deviceId = window.localStorage.getItem("preferredCameraDeviceId");
      if (!deviceId) {
        deviceId = vd?.[0]?.deviceId;
        localStorage.setItem("preferredCameraDeviceId", deviceId);
      }
      setVideoDevices(applyDeviceLabels(vd));

      createRecording(deviceId);
    };
    videoSetup();

    return () => {
      //make sure to end all streams
      if (canvasStream.current) {
        canvasStream.current.getTracks().forEach((track) => track.stop());
      }
      if (videoStream.current) {
        videoStream.current.getTracks().forEach((track) => track.stop());
      }
      clearInterval(poll.current);
      stopSegmenting();
    };
  }, []);

  useEffect(() => {
    if (countdownSecond === null) return;

    if (countdownSecond === 0) {
      setCountdownSecond(null);
      chunks.current = []; // Wipe old data chunks
      mediaRecorder.current.start(1); // Start recorder with 10ms buffer
      setStatus(STATES.recording);
    } else {
      setStatus(STATES.recordingCountdown);
      const timer = setTimeout(() => {
        setCountdownSecond(countdownSecond - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdownSecond]);

  const applyDeviceLabels = (devices) =>
    devices.map((device) => {
      let label = device.label;
      if (!label) label = device.deviceId;
      label = device.label.replace(/\s\([a-z\d]+:[a-z\d]+\)/, "").trim();
      return { deviceId: device.deviceId, label };
    });

  const setStream = async (deviceId) => {
    try {
      stopSegmenting();

      if (videoStream.current) {
        videoStream.current.getTracks().forEach((track) => track.stop());
      }

      localStorage.setItem("preferredCameraDeviceId", deviceId);
      videoStream.current = await window.navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          height: { ideal: 1080, max: 1080 },
          width: { ideal: 1920, max: 1920 },
          deviceId: deviceId,
        },
      });

      if (videoDevices.length === 0) {
        const vd = (await window.navigator.mediaDevices.enumerateDevices()).filter(
          (device) => device.kind === "videoinput"
        );
        setVideoDevices(applyDeviceLabels(vd));
      }

      const video = videoRef.current;
      // Older browsers don't support srcObject
      if ("srcObject" in video) {
        video.srcObject = videoStream.current;
      } else {
        video.src = window.URL.createObjectURL(videoStream.current);
      }

      setStatus(STATES.init);
      setSelectedVideoDeviceId(deviceId);
    } catch (err) {
      let msg = `Error accessing video devices: ${err}`;
      if (err.name === "NotAllowedError") {
        msg = "Permission denied for video access.";
      } else if (err.name === "NotFoundError") {
        msg = "No video device found.";
      }
      setStatus(STATES.error);
      setErrorMessage(msg);
      setIsLoading(false);
    }
  };

  const createRecording = async (deviceId) => {
    try {
      // Stop all streams
      if (canvasStream.current) {
        canvasStream.current.getTracks().forEach((track) => track.stop());
      }
      clearInterval(poll.current);

      const video = videoRef.current;
      const canvas = canvasRef.current;

      setStream(deviceId);

      // Start video when ready
      video.onloadedmetadata = async () => {
        if (videoStream.current) {
          video.play();
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          segmentBackground(video, canvas);
          setIsLoading(false);
          applyFilter("None");

          // Get audio of video to combine with canvas stream
          canvasStream.current = canvas.captureStream();
          const combinedStream = new MediaStream([
            ...canvasStream.current.getVideoTracks(),
            ...videoStream.current.getAudioTracks(),
          ]);
          mediaRecorder.current = new MediaRecorder(combinedStream, {
            mimeType: "video/mp4",
          });

          chunks.current = [];
          // listen for data from media recorder
          mediaRecorder.current.ondataavailable = (e) => {
            if (e?.data?.size > 0) {
              chunks.current.push(e.data);
            }
          };

          mediaRecorder.current.onstop = () => {
            if (previewRef.current) {
              const blob = new Blob(chunks.current, {
                type: "video/mp4",
              });
              previewRef.current.src = URL.createObjectURL(blob);
              previewRef.current.play();
              setBlob(blob);
              setStatus(STATES.recorded);
            }
          };

          startSegmenting();
        }
      };

      setStatus(STATES.init);
    } catch (err) {
      setErrorMessage(err.toString());
      setStatus(STATES.error);
    }
  };

  const stopRecording = (e) => {
    try {
      e.preventDefault();
      mediaRecorder.current.stop();
      stopSegmenting();
    } catch {
      setErrorMessage(ERROR_RECORDING);
      setStatus(STATES.error);
    }
  };

  const handleRestart = () => {
    setStatus(STATES.init);
    videoRef.current.play();
    startSegmenting();
  };

  const uploadFile = (event) => {
    const file = event?.target?.files?.[0];
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
    previewRef.current.src = URL.createObjectURL(file);
    previewRef.current.play();
    setBlob(file);
    setStatus(STATES.recorded);
  };

  const pollForUploadStatus = (videoId) => {
    poll.current = setInterval(async () => {
      const video = await getVideo(videoId);
      if (!video || video?.status !== "mediaURLUploaded") {
        return;
      }

      if ((video?.status ?? "create") === "error") {
        setErrorMessage(
          "There was an error transcoding your video. Try removing or deleting this video and starting over."
        );
        setStatus(STATES.error);
      } else {
        clearInterval(poll.current);
        setIsLoading(false);
        setStatus(STATES.completed);
        props.onComplete(video);
      }
    }, 5000);
  };

  const onUploadProgress = (progressEvent) => {
    try {
      const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadPercent(percent);
    } catch (err) {
      console.error(err.message);
    }
  };

  const upload = async (flob) => {
    if (!flob) {
      return;
    }
    try {
      if (flob?.size > MAX_FILE_SIZE)
        throw new Error(
          "File must be smaller than 700mb. Recordings must be less than 60 minutes. Please re-try with smaller file."
        );

      previewRef.current.pause();
      setIsLoading(true);
      setStatus(STATES.uploading);

      let videoId = props.video.id;
      if (!videoId) {
        videoId = (await createVideos([props.video]))[0].id;
      }

      const result = await createVideoSignedUrl({ videoId });
      const presignedPost = result?.presignedPost;
      if (!presignedPost) {
        throw new Error("Error creating upload url");
      }

      const {
        readUrl = null,
        presignedPost: { fields = [] },
      } = result;

      const formData = new FormData();
      formData.append("acl", "public-read");
      for (const field in fields) {
        formData.append(field, fields[field]);
      }
      formData.append("file", flob);
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress,
      };
      await editVideo(videoId, {
        status: "webMUploaded",
        videoUrl: readUrl,
        speakerName: props?.recorderName,
      });

      await axios.post(presignedPost.url, formData, config);
      setStatus(STATES.processing);
      pollForUploadStatus(videoId);
    } catch (err) {
      const errorMessage = err?.response?.data?.message ?? err.message;
      setErrorMessage(errorMessage);
      setStatus(STATES.error);
    }
  };

  const applyFilter = (filter, blur = "") => {
    if (filter === "Image") {
      fileInput.current.click();
      return;
    }

    setBackground(filter + blur);
    if (filter === "Blur") {
      applyBlur(blur);
    } else {
      applyBlur(0);
    }
  };

  const applyImageFilter = (e) => {
    if (e.target?.files?.[0]) {
      const image = new Image();
      image.src = URL.createObjectURL(e.target.files[0]);
      applyImageBackground(image);
      setBackground("Image");
      fileInput.current.value = null;
    }
  };

  const getLoaderText = () => {
    if (status === STATES.uploading) {
      return `Uploading`;
    } else if (status === STATES.processing) {
      return "Processing";
    } else {
      return "Loading";
    }
  };

  return (
    <div className={styles.video_wrapper}>
      <div className={styles.toolbar}>
        <FormControl>
          <InputLabel htmlFor="selectedVideoDevice">Camera</InputLabel>
          <Select
            value={selectedVideoDeviceId}
            disabled={![STATES.init, STATES.error].includes(status)}
            onChange={(e) => setStream(e.target.value)}
            inputProps={{
              name: "selectedVideoDeviceLabel",
              id: "selectedVideoDeviceLabel",
            }}>
            {videoDevices.map(({ deviceId, label }) => {
              return (
                <MenuItem value={deviceId} key={deviceId}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {!isSafari.current && (
          <FormControl>
            <InputLabel htmlFor="selectedVideoDevice">Background</InputLabel>
            <Select value={background} disabled={status !== STATES.init}>
              <MenuItem onClick={() => applyFilter("None")} value="None">
                None
              </MenuItem>
              <MenuItem onClick={() => applyFilter("Blur", 7)} value="Blur7">
                Slight Blur
              </MenuItem>
              <MenuItem onClick={() => applyFilter("Blur", 15)} value="Blur15">
                Blur
              </MenuItem>
              <MenuItem onClick={() => applyFilter("Image")} value="Image">
                Image <span className={styles.betaLabel}>BETA</span>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
      <div className={styles.screen_wrapper} style={{ flexDirection: "column" }}>
        <canvas
          ref={canvasRef}
          className={`${styles.video_player} ${
            [STATES.recorded, STATES.uploading, STATES.processing].includes(status)
              ? styles.hidden
              : ""
          } ${isMirrored ? styles.mirrored : ""}`}
        />
        <video ref={videoRef} className={styles.hidden} muted />
        <video
          ref={previewRef}
          id="mediaPlayer"
          className={`${styles.video_player} ${
            [STATES.recorded, STATES.uploading, STATES.processing].includes(status)
              ? ""
              : styles.hidden
          }`}
          controls={![STATES.uploading, STATES.processing].includes(status)}>
          {status === STATES.recorded && <source src={URL.createObjectURL(blob)} />}
          <p>{errorMessage}</p>
        </video>
        {status === STATES.error && (
          <span className={styles.error_text}>{errorMessage}</span>
        )}
        {status === STATES.recordingCountdown && (
          <span className={styles.countdown_text}>{countdownSecond}</span>
        )}
        {[STATES.init, STATES.recordingCountdown].includes(status) && (
          <div className={`${styles.indicator} ${styles.standby}`} />
        )}
        {status === STATES.recording && (
          <div className={`${styles.indicator} ${styles.recording}`} />
        )}
        {![STATES.recorded, STATES.uploading, STATES.processing].includes(status) && (
          <IconButton className={styles.flip} onClick={() => setIsMirrored(!isMirrored)}>
            <i className="material-icons">flip</i>
          </IconButton>
        )}
        {isLoading && (
          <Box className={styles.loader} position="relative" display="inline-flex">
            <CircularProgress
              color="secondary"
              size={100}
              variant={status === STATES.uploading ? "determinate" : "indeterminate"}
              value={status === STATES.uploading ? uploadPercent : 100}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <span className={styles.loader_text}>{getLoaderText()}</span>
            </Box>
          </Box>
        )}
      </div>
      <div className={styles.toolbar}>
        {status === STATES.init && (
          <div>
            <Button
              className="trappbtn btn-green"
              onClick={() => setCountdownSecond(3)}
              startIcon={<i className="material-icons">videocam</i>}
              size="large">
              Record
            </Button>
          </div>
        )}
        {status === STATES.recording && (
          <div>
            <Button
              className="trappbtn btn-red"
              onClick={stopRecording}
              startIcon={<i className="material-icons">stop</i>}
              size="large">
              Stop
            </Button>
          </div>
        )}
        {[STATES.recorded, STATES.completed].includes(status) && (
          <div>
            <Button
              className="trappbtn btn-red"
              onClick={handleRestart}
              startIcon={<i className="material-icons">replay</i>}
              size="large">
              Restart
            </Button>
          </div>
        )}
        {[STATES.init, STATES.recorded, STATES.error].includes(status) && (
          <div>
            <input
              ref={fileUpload}
              type="file"
              accept="video/mp4,video/x-m4v,video/webm,video/*"
              id="mediaPlayer-1"
              className={styles.hidden}
              onChange={uploadFile}
            />
            <label htmlFor="mediaPlayer-1">
              <Button
                className="trappbtn btn-green"
                onClick={() =>
                  [STATES.init, STATES.error].includes(status)
                    ? fileUpload.current.click()
                    : upload(blob)
                }
                startIcon={<i className="material-icons">file_upload</i>}
                size="large">
                {[STATES.init, STATES.error].includes(status) ? "Upload" : "Save"}
              </Button>
            </label>
          </div>
        )}
      </div>
      <input
        ref={fileInput}
        className={styles.hidden}
        onChange={applyImageFilter}
        type="file"
        accept="image/*"
      />
    </div>
  );
}

VideoRecorder.defaultProps = {
  recorderName: "Anonymous",
};

VideoRecorder.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    foldersFilter: PropTypes.arrayOf(PropTypes.string),
    uploadType: PropTypes.string,
    createdByTask: PropTypes.bool,
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
  recorderName: PropTypes.string,
};
